<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotEquipment' }">设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>新增设备</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="140px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备名称" prop="equipmentName">
                <el-input v-model="form.equipmentName" placeholder="请输入设备名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备号" prop="eid">
                <el-input v-model="form.eid" placeholder="请输入设备号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备激活码" prop="">
                <el-input v-model="form.activationCode" placeholder="请输入设备激活码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备序列号" prop="">
                <el-input v-model="form.serialNumber" placeholder="请输入设备序列号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备型号" prop="model">
                <el-input v-model="form.model" placeholder="请输入设备型号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备类型" prop="deviceTypeName">
                <el-row :gutter="0">
                  <el-col :span="19">
                    <el-input v-model="form.deviceTypeName" disabled placeholder="请选择设备类型"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button type="primary" @click="addDevice">选择</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="供应商" prop="supplier">
                <el-row :gutter="0">
                  <el-col :span="19">
                    <el-input v-model="form.supplier" disabled placeholder="请选择供应商"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button type="primary" @click="addSupplier">选择</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="品牌" prop="equipmentBrand">
                <el-row :gutter="0">
                  <el-col :span="19">
                    <el-input v-model="form.equipmentBrand" disabled placeholder="请选择品牌"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button type="primary" @click="addBrand" :disabled="brandFlag">选择</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="水厂名称" prop="waterworks">
                <el-input v-model="form.waterworks" placeholder="请输入水厂名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="泵房编号" prop="position">
                <el-input v-model="form.position" placeholder="请输入泵房编号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="数字孪生体" prop="twin">
                <el-select v-model="form.twin" style="width:100%;" placeholder="请选择是否支持数字孪生体">
                  <el-option
                    v-for="item in digitalTwin"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数据存储间隔时间" prop="saveInterval">
                <el-row :gutter="0">
                  <el-col :span="15">
                    <div v-show="!isCustom">
                      <el-select v-model="form.saveInterval" style="width:100%;" placeholder="请选择数据存储间隔时间">
                        <el-option
                          v-for="item in intervalTime"
                          :label="item.name"
                          :value="item.second"
                          :key="item.second"></el-option>
                      </el-select>
                    </div>
                    <div v-show="isCustom">
                      <el-input v-model.number="form.saveInterval" style="width:85%;margin-right:5px;" placeholder="请输入数据存储间隔时间"></el-input>
                      <span>秒</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="primary" style="margin-left:14px;width:112px;" @click="customInput">{{customText}}</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="在线设备间隔时间" prop="onlineEquipment">
                <el-row :gutter="0">
                  <el-col :span="15">
                    <div v-show="!isOnlineEquipmentCustom">
                      <el-select v-model="form.onlineEquipment" style="width:100%;" placeholder="请选择在线设备间隔时间">
                        <el-option
                          v-for="item in intervalTime"
                          :label="item.name"
                          :value="item.second"
                          :key="item.second"></el-option>
                      </el-select>
                    </div>
                    <div v-show="isOnlineEquipmentCustom">
                      <el-input v-model.number="form.onlineEquipment" style="width:85%;margin-right:5px;" placeholder="请输入在线设备间隔时间"></el-input>
                      <span>秒</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="primary" style="margin-left:14px;width:112px;" @click="onlineEquipmentInput">{{onlineEquipmentText}}</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="remarks">
                <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotEquipment')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <!-- 添加设备 -->
      <el-drawer
        title="添加设备"
        :with-header="false"
        :visible.sync="drawerDevice"
        direction="rtl"
        size="600px">
        <div class="drawer-title">添加设备</div>
        <div class="drawer-content table-tree">
          <div class="table">
            <el-table :data="tableDeviceData" height="700" v-loading="tableDeviceLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="deviceRadio" @change="checkDeviceChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="设备类型名称"></el-table-column>
              <el-table-column prop="deviceTypeCode" label="设备类型编码"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawerDeviceCancel=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerDeviceOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 添加品牌 -->
      <el-drawer
        title="添加品牌"
        :with-header="false"
        :visible.sync="drawerBrand"
        direction="rtl"
        size="600px">
        <div class="drawer-title">添加品牌</div>
        <div class="drawer-content table-tree">
          <div class="table">
            <el-table :data="tableBrandData" height="700" v-loading="tableBrandLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="brandRadio" @change="checkBrandChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="equipmentBrand" label="品牌名称"></el-table-column>
              <el-table-column prop="equipmentBrandCode" label="品牌编码"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawerBrandCancel=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerBrandOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 添加供应商 -->
      <el-drawer
        title="添加供应商"
        :with-header="false"
        :visible.sync="drawerSupplier"
        direction="rtl"
        size="600px">
        <div class="drawer-title">添加供应商</div>
        <div class="drawer-content table-tree">
          <div class="table">
            <el-table :data="tableSupplierData" height="700" v-loading="tableSupplierLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="supplierRadio" @change="checkSupplierChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="supplier" label="供应商名称"></el-table-column>
              <el-table-column prop="supplierCode" label="供应商编码"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawerSupplierCancel=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerSupplierOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        deviceTypeName: '',
        supplier: '',
        equipmentBrand: '',
        saveInterval: null,
        onlineEquipment: null
      },
      rules: {
        equipmentName:[{ required: true, message: '必填项', trigger: 'blur' }],
        eid:[{ required: true, message: '必填项', trigger: 'blur' }],
        activationCode:[{ required: true, message: '必填项', trigger: 'blur' }],
        serialNumber:[{ required: true, message: '必填项', trigger: 'blur' }],
        model:[{ required: true, message: '必填项', trigger: 'blur' }],
        deviceTypeName:[{ required: true, message: '必填项', trigger: 'blur' }],
        supplier:[{ required: true, message: '必填项', trigger: 'blur' }],
        equipmentBrand:[{ required: true, message: '必填项', trigger: 'blur' }],
        waterworks:[{ required: true, message: '必填项', trigger: 'blur' }],
        position:[{ required: true, message: '必填项', trigger: 'blur' }],
        twin:[{ required: true, message: '必填项', trigger: 'blur' }],
        saveInterval:[{ required: true, message: '必填项', trigger: 'change' }],
        onlineEquipment:[{ required: true, message: '必填项', trigger: 'change' }],
      },
      disabled: false,
      allFileList: [],

      // 设备类型
      drawerDevice: false,
      deviceRadio: '',
      tableDeviceData: [],
      tableDeviceSelect: {},
      tableDeviceLoading: false,

      // 供应商
      drawerSupplier: false,
      supplierRadio: '',
      tableSupplierData: [],
      tableSupplierSelect: {},
      tableSupplierLoading: false,

      // 品牌
      drawerBrand: false,
      brandRadio: '',
      tableBrandData: [],
      tableBrandSelect: {},
      tableBrandLoading: false,
      brandFlag: true,

      digitalTwin: [
        { id: 0, name: '不支持孪生体' },
        { id: 1, name: '支持孪生体' },
      ],
      intervalTime: [
        { second: 1, name: '1秒' },
        { second: 5, name: '5秒' },
        { second: 10, name: '10秒' },
        { second: 30, name: '30秒' },
        { second: 60, name: '1分钟' },
        { second: 300, name: '5分钟' },
        { second: 600, name: '10分钟' },
        { second: 1800, name: '30分钟' },
        { second: 3600, name: '1小时' },
        { second: 43200, name: '12小时' },
        { second: 86400, name: '24小时' },
      ],
      isCustom: false,
      isOnlineEquipmentCustom: false,
      customText: '自定义输入',
      onlineEquipmentText: '自定义输入'
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$ajax.post("iotEquipmentCreate", {
            activationCode: this.form.activationCode?this.form.activationCode:null,
            deviceTypeCode: this.form.deviceTypeCode,
            eid: this.form.eid,
            equipmentBrandCode: this.form.equipmentBrandCode,
            equipmentName: this.form.equipmentName,
            serialNumber: this.form.serialNumber?this.form.serialNumber:null,
            supplierCode: this.form.supplierCode,
            model: this.form.model,
            waterworks: this.form.waterworks,
            position: this.form.position,
            digitalTwin: this.form.twin,
            saveInterval: this.form.saveInterval,
            onlineIntervalCheck: this.form.onlineEquipment,
            remarks: this.form.remarks?this.form.remarks:null,
          }).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/iotEquipment");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(fileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    },

    // 设备类型
    loadDeviceTypeData() {
      this.tableDeviceLoading = true
      this.$ajax.post('iotDeviceQuery').then(res => {
        this.tableDeviceData = res.data
        this.tableDeviceLoading = false
      }).catch(err=>{
        this.tableDeviceLoading = false
      })
    },
    // 供应商信息
    loadSupplierData() {
      this.tableSupplierLoading = true
      this.$ajax.post('iotSupplierQuery').then(res => {
        this.tableSupplierData = res.data
        this.tableSupplierLoading = false
      }).catch(err=>{
        this.tableSupplierLoading = false
      })
    },
    // 获取供应商下品牌
    loadSupplierBrand(supplierCode) {
      this.$ajax.post('supplierDeviceQuery',{
        supplierCode: supplierCode
      }).then(res => {
        this.tableBrandData = res.data
      })
    },

    addDevice() {
      this.loadDeviceTypeData()
      this.deviceRadio = ''
      this.drawerDevice = true
    },
    // 勾选信息数据
    checkDeviceChange(row) {
      this.deviceRadio = row.id
      this.tableDeviceSelect = row // 勾选
    },
    drawerDeviceOK() {
      this.form.deviceTypeCode = this.tableDeviceSelect.deviceTypeCode
      this.form.deviceTypeName = this.tableDeviceSelect.name
      this.drawerDevice = false
    },

    addBrand() {
      this.loadSupplierBrand(this.tableSupplierSelect.supplierCode)
      this.brandRadio = ''
      this.drawerBrand = true
    },
    // 勾选信息数据
    checkBrandChange(row) {
      this.brandRadio = row.id
      this.tableBrandSelect = row // 勾选
    },
    drawerBrandOK() {
      this.form.equipmentBrandCode = this.tableBrandSelect.equipmentBrandCode
      this.form.equipmentBrand = this.tableBrandSelect.equipmentBrand
      this.drawerBrand = false
    },

    addSupplier() {
      this.loadSupplierData()
      this.supplierRadio = ''
      this.drawerSupplier = true
    },
    // 勾选信息数据
    checkSupplierChange(row) {
      this.supplierRadio = row.id
      this.tableSupplierSelect = row // 勾选
    },
    drawerSupplierOK() {
      this.form.supplierCode = this.tableSupplierSelect.supplierCode
      this.form.supplier = this.tableSupplierSelect.supplier
      this.drawerSupplier = false
      this.brandFlag = false
    },
    // 自定义切换
    customInput() {
      this.form.saveInterval = null
      this.isCustom = !this.isCustom
      if(this.isCustom) {
        this.customText = '默认选择'
      }else {
        this.customText = '自定义输入'
      }
    },
    // 在线设备自定义切换
    onlineEquipmentInput() {
      this.form.onlineEquipment = null
      this.isOnlineEquipmentCustom = !this.isOnlineEquipmentCustom
      if(this.isOnlineEquipmentCustom) {
        this.onlineEquipmentText = '默认选择'
      }else {
        this.onlineEquipmentText = '自定义输入'
      }
    }
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
</style>